<template>
  <v-row justify="center" align="center">
    <v-col cols="12">
      <v-card>
        <v-row>
          <v-col cols="6" class="pa-0" style="background-color: aqua">
            <v-carousel cycle height="720" hide-delimiters class="mb-n3">
              <v-carousel-item>
                <v-sheet color="purple lighten-5" height="100%">
                  <v-row class="fill-height" align="center" justify="center">
                    <div>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center"
                      >
                        <v-img
                          :src="require('@/assets/login/' + appL)"
                          max-width="180"
                        ></v-img>
                      </v-row>
                      <div
                        class="text-center mt-5 px-5"
                        v-if="appT == 'SIMASN'"
                      >
                        <h2>Sistem Informasi Manajemen</h2>
                        <h2>Aparatur Sipil Negara (SimASN)</h2>
                        <h3 class="font-weight-light">{{ appLK }}</h3>
                      </div>
                      <div
                        class="text-center mt-5 px-5"
                        v-if="appT == 'SIMPELASN'"
                      >
                        <h3>Sistem Informasi Manajemen dan Pelayanan</h3>
                        <h3>Aparatur Sipil Negara (SimpelASN)</h3>
                        <h3 class="font-weight-light">{{ appLK }}</h3>
                      </div>
                    </div>
                  </v-row>
                </v-sheet>
              </v-carousel-item>

              <v-carousel-item>
                <v-sheet color="grey lighten-2" height="100%">
                  <v-row class="fill-height" align="center" justify="center">
                    <div>
                      <div class="mb-10">
                        <v-row>
                          <v-col cols="6">
                            <v-img
                              src="@/assets/login/bkn-logo.png"
                              class="mx-auto"
                            ></v-img>
                          </v-col>

                          <v-col cols="6">
                            <v-img
                              src="@/assets/login/logo-satu-asn.png"
                              class="mx-auto"
                              max-width="110"
                            ></v-img>
                          </v-col>
                        </v-row>
                      </div>

                      <div class="text-center mb-10">
                        <h2>TERINTEGRASI DENGAN SISTEM BKN</h2>
                        <h3 class="font-weight-light">
                          MENUJU SATU DATA ASN DENGAN
                        </h3>
                      </div>

                      <div>
                        <v-row align="center" justify="center">
                          <div class="text-center">
                            <v-img
                              src="@/assets/login/logo-sso.png"
                              max-width="60"
                              class="ml-2"
                            ></v-img>
                            <h3>SSO BKN</h3>
                          </div>

                          <v-img
                            src="@/assets/login/logo-mysapk.png"
                            class="mx-10"
                            max-width="100"
                          ></v-img>

                          <div class="text-center">
                            <v-img
                              src="@/assets/login/logo-siasn.png"
                              max-width="60"
                            ></v-img>
                            <h3>SIASN</h3>
                          </div>
                        </v-row>
                      </div>
                    </div>
                  </v-row>
                </v-sheet>
              </v-carousel-item>

              <v-carousel-item>
                <v-sheet color="light-blue lighten-4" height="100%">
                  <v-row class="fill-height" align="center" justify="center">
                    <div>
                      <div>
                        <div class="text-center mb-5">
                          <h2>Integrasi Tanda Tangan Elektronik (TTE)</h2>
                          <span class="font-weight-light">
                            Balai Sertifikasi Elektronik (BSrE) Badan Siber dan
                            Sandi Negara (BSSN)
                          </span>
                        </div>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center"
                        >
                          <v-img
                            src="@/assets/login/full-bsre-logo.png"
                            class="mx-4"
                            max-width="250"
                          ></v-img>

                          <v-img
                            src="@/assets/login/logo-bsn.png"
                            class="mx-4"
                            max-width="90"
                          ></v-img>
                        </v-row>
                      </div>
                    </div>
                  </v-row>
                </v-sheet>
              </v-carousel-item>
            </v-carousel>
          </v-col>

          <v-col cols="6" class="my-auto">
            <div class="pa-6">
              <div class="mb-6">
                <v-row dense>
                  <v-img
                    :src="require('@/assets/login/' + logo)"
                    max-width="75"
                  ></v-img>
                  <div class="ml-3">
                    <h2 class="font-weight-light">LOGIN</h2>
                    <h3>{{ title.toUpperCase() }}</h3>
                  </div>
                </v-row>
              </div>

              <div class="mb-6" v-if="alertG">
                <v-alert v-model="alertG" type="error" dense dismissible>
                  {{ alertM }}
                </v-alert>
              </div>

              <div class="mb-6">
                <v-alert dense type="info" text>
                  Silahkan login menggunakan akun
                  <strong>MySAPK BKN</strong> anda
                </v-alert>
              </div>

              <form>
                <v-text-field
                  v-model="username"
                  label="Nomor Induk Pegawai"
                  outlined
                ></v-text-field>

                <v-text-field
                  v-model="password"
                  label="Password"
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show ? 'text' : 'password'"
                  @click:append="show = !show"
                  outlined
                ></v-text-field>

                <v-text-field
                  v-model="otp"
                  label="TOTP"
                  outlined
                ></v-text-field>
                <div class="ml-3 mt-n7">
                  <small class="caption error--text">
                    *TOTP diisi jika user login menggunakan TOTP, apabila tidak
                    TOTP bisa dikosongkan
                  </small>
                </div>

                <v-row class="mt-2">
                  <v-col cols="12" md="12">
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-btn
                          class="mb-3"
                          color="primary"
                          block
                          @click="login()"
                        >
                          <span class="font-weight-black"> LOGIN </span>
                        </v-btn>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-btn
                          class="mb-3"
                          color="white"
                          block
                          @click="google()"
                        >
                          <v-img
                            src="@/assets/login/google.png"
                            class="mr-2"
                            max-width="20"
                          ></v-img>
                          <span class="font-weight-black">
                            Login dengan Google
                          </span>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="12">
                        <v-btn color="primary" text block @click="lupapass()">
                          <span> LUPA PASSWORD </span>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </form>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    show: false,
    alertG: false,

    title: process.env.VUE_APP_OPDNAME,
    logo: process.env.VUE_APP_OPDLOGO,

    appN: process.env.VUE_APP_FULLNAME,
    appLK: process.env.VUE_APP_APPLOKASI,
    appT: process.env.VUE_APP_APPNAME,
    appL: process.env.VUE_APP_APPLOGO,
    appFL: process.env.VUE_APP_FULLLOGO,

    alertM: "",
    userEmail: "",
    userPassword: "",

    username: "",
    password: "",
    otp: "",
  }),

  methods: {
    login() {
      const data = {
        username: this.username,
        password: this.password,
        otp: this.otp,
      };

      const url =
        process.env.VUE_APP_AUTH +
        "authorize?client_id=" +
        this.$route.query.client_id +
        "&response_type=" +
        this.$route.query.response_type;

      this.http
        .post(url, data)
        .then((res) => {
          if (res.data.success) {
            this.alertG = false;
            window.location.href = res.data.mapData.redirect_uri;
          } else {
            this.alertG = true;
            this.alertM = res.data.message;
          }
        })
        .catch((err) => {
          console.log(err);
          this.alertG = true;
          this.alertM = err.response.data.message;
        });
    },

    lupapass() {
      this.$router
        .push("/resetpassword?client_id=" + this.$route.query.client_id)
        .catch(() => {});
    },

    google() {
      window.location.href = "https://account.gorontaloprov.go.id/oauth/google";
    },
  },
};
</script>
