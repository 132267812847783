<template>
  <v-row justify="center" align="center">
    <v-col cols="12">
      <v-card>
        <div class="pa-6">
          <div class="mb-10">
            <v-row dense>
              <v-img
                :src="require('@/assets/login/' + logo)"
                max-width="60"
              ></v-img>
              <div class="ml-3">
                <h3 class="font-weight-light">LOGIN</h3>
                <strong>{{ title.toUpperCase() }}</strong>
              </div>
            </v-row>
          </div>

          <div class="mb-10" v-if="alertG">
            <v-alert v-model="alertG" type="error" dense dismissible>
              {{ alertM }}
            </v-alert>
          </div>

          <div>
            <v-alert dense type="info" text>
              Silahkan login menggunakan akun <strong>MySAPK BKN</strong> anda
            </v-alert>
          </div>

          <form>
            <v-text-field
              v-model="username"
              label="Nomor Induk Pegawai"
              outlined
            ></v-text-field>

            <v-text-field
              v-model="password"
              label="Password"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              @click:append="show = !show"
              outlined
            ></v-text-field>

            <v-text-field v-model="otp" label="TOTP" outlined></v-text-field>
            <div class="ml-3 mt-n7">
              <small class="caption error--text">
                *TOTP diisi jika user login menggunakan TOTP, apabila tidak TOTP
                bisa dikosongkan
              </small>
            </div>

            <v-row>
              <v-col cols="12" md="12">
                <v-btn class="mb-3" color="primary" block @click="login()">
                  <span class="font-weight-black"> LOGIN </span>
                </v-btn>
                <v-btn color="primary" text block @click="lupapass()">
                  <span class="font-weight-black"> LUPA PASSWORD </span>
                </v-btn>
              </v-col>
            </v-row>
          </form>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    show: false,
    alertG: false,

    title: process.env.VUE_APP_OPDNAME,
    logo: process.env.VUE_APP_OPDLOGO,

    appT: process.env.VUE_APP_APPNAME,
    appL: process.env.VUE_APP_APPLOGO,
    appFL: process.env.VUE_APP_FULLLOGO,

    alertM: "",
    userEmail: "",
    userPassword: "",

    username: "",
    password: "",
    otp: "",
  }),

  methods: {
    randomColor(id) {
      const r = () => Math.floor(256 * Math.random());

      return (
        this.colorCache[id] ||
        (this.colorCache[id] = `rgb(${r()}, ${r()}, ${r()})`)
      );
    },

    login() {
      const data = {
        username: this.username,
        password: this.password,
        otp: this.otp,
      };

      const url =
        process.env.VUE_APP_AUTH +
        "authorize?client_id=" +
        this.$route.query.client_id +
        "&response_type=" +
        this.$route.query.response_type;

      this.http
        .post(url, data)
        .then((res) => {
          if (res.data.success) {
            this.alertG = false;
            window.location.href = res.data.mapData.redirect_uri;
          } else {
            this.alertG = true;
            this.alertM = res.data.message;
          }
        })
        .catch((err) => {
          console.log(err);
          this.alertG = true;
          this.alertM = err.response.data.message;
        });
    },

    lupapass() {
      this.$router
        .push("/resetpassword?client_id=" + this.$route.query.client_id)
        .catch(() => {});
    },
  },
};
</script>
